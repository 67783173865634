// This is where we define and import all our typography that we make available globally
@use "./app/styles/typography";
@use "./app/styles/colors" as *;
@use "./app/styles/layout" as *;

html,
body {
  min-height: 100vh;
  width: 100vw;
  margin: 0;
  text-align: center;
  display: flex;
  overflow-x: hidden;
}

body {
  margin: 0;
}

.mdc-dialog__surface {
  border-radius: 16px !important;
}
// used because dialoag materials library is blackboxed for css changes -zj

.kinput {
  background-color: $white3;
  border-radius: 8px;
  border: none;
  padding: 6px 16px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}

.input-bordered {
  border: 1px solid $grey2;
  border-radius: 8px;
  background-color: $white1;
  color: $black;
  padding: 8px 0 8px 16px;
  * {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  &:focus {
    outline: none;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  }
  &:disabled {
    opacity: 0.5;
  }
}

// This is the CSS for the toggle switch, would love to abstract this to a reusable component or universal styling
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: flex;
  width: 40px;
  height: 22px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d0d0d0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 35px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 3px;
  background-color: #ffffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: $ks_accent1_00;
}

input:focus + .slider {
  box-shadow: 0 0 1px $ks_accent1_00;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

input {
  border: 1px solid #dadde5;
  border-radius: 8px;
  background-color: #fff;

  &:focus {
    outline: none;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  }

  &:not(:focus):not(:placeholder-shown):invalid,
  &.inputError {
    box-shadow: 0px 0px 4px 0px #af2f0d;
  }

  &::placeholder {
    color: #8289a1;
    font-style: italic;
  }
}

a.green-link {
  text-decoration: none;
  color: #00828e;
  cursor: pointer;

  &:hover {
    color: darken(#00828e, 8%);
  }
  &:active {
    color: lighten(#00828e, 5%);
  }
}

.center-text {
  text-align: center;
}
