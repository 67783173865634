@use "./colors" as *;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
// This is where we define our typography and assign initial paragraph, header, label, and anchor styles

body {
  font-family: Inter;
  font-size: 16px;
}

* {
  font-family: Inter;
}

// displays and h1
.kdisplay-huge {
  font-size: 63px;
  font-style: normal;
  font-weight: 700;
  line-height: 78px;
}

.kdisplay-large {
  font-size: 57px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
}

.kdisplay-medium,
h1 {
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
}

.kdisplay-small {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
}

.kdisplay-tiny {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}

.ktitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  &.strong {
    font-weight: 700;
  }
}

// titles, h4, and h3
.ktitle-huge {
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
}

.ktitle-large {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}

.ktitle-medium,
h3 {
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}

.ktitle-small,
h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.ktitle-tiny {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

// subtitles
.ksubtitle-huge {
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.ksubtitle-large {
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.ksubtitle-medium {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.024px;
}

.ksubtitle-small {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.014px;
}

.ksubtitle-tiny {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: 0.012px;
}

// labels and label
.klabel-huge {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.1px;
}

.klabel-large {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.klabel-medium,
label {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: 0.1px;
}

.klabel-small {
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 145.455% */
  letter-spacing: 0.2px;
}

.klabel-tiny {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 160% */
  letter-spacing: 0.3px;
}

.klabel-super {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #649336;
  vertical-align: super;
  padding: 0 0 0 8px;
}

// bodies and paragraph
.kbody-huge {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 155.556% */
}

.kbody-large {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  &.strong {
    font-weight: 700;
  }
  /* 150% */
}

.kbody-large-strong {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.kbody-medium,
p,
span {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 142.857% */
}

.kbody-small {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}

.kbody-small-strong {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}

.kbody-tiny {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 160% */
}

//captions
.kcaption-huge {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.kcaption-large {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.kcaption-medium {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.kcaption-small {
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px;
  /* 120% */
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.kcaption-tiny {
  font-size: 8px;
  font-style: normal;
  font-weight: 300;
  line-height: 10px;
  /* 125% */
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

// links
.klink-large {
  color: $ks_accent1_00;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.5px;
  text-decoration-line: underline;

  &:hover {
    color: $ks_accent1_03;
  }

  &:active {
    color: $ks_accent1_01;
  }
}

.klink-medium {
  color: $ks_accent1_00;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.5px;
  text-decoration-line: underline;

  &:hover {
    color: $ks_accent1_03;
  }

  &:active {
    color: $ks_accent1_01;
  }
}

.klink-small {
  color: $ks_accent1_00;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: 0.5px;
  text-decoration-line: underline;

  &:hover {
    color: $ks_accent1_03;
  }

  &:active {
    color: $ks_accent1_01;
  }
}

.klink-tiny {
  color: $ks_accent1_00;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 160% */
  letter-spacing: 0.5px;
  text-decoration-line: underline;

  &:hover {
    color: $ks_accent1_03;
  }

  &:active {
    color: $ks_accent1_01;
  }
}

// placeholders for any inputs
.kplaceholder {
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.2px;
}

// list items
.klist-item {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  /* 133.333% */
  letter-spacing: 0.1px;
}

.klist-subitem {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 16.8px;
  /* 133.333% */
  letter-spacing: 0.1px;
}

.kheader-strong {
  font-family: Inter;
  font-size: 21px;
  font-weight: 700;
  line-height: 25.41px;
}
